/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { Box, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useForm } from 'react-hook-form';

import { Button } from '@components';

import styles from './editARNMDocumentModal.style';

export const EditARNMDocumentModal = ({
  isOpen,
  document,
  onClose,
  onSubmit,
}) => {
  const { register, handleSubmit, formState, reset } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: {
      customer: document?.tags?.includes('customer'),
      servicePoint: document?.tags?.includes('service-point'),
      insurance: document?.tags?.includes('insurance'),
    },
  });

  useEffect(() => {
    if (document) {
      reset({
        customer: document?.tags?.includes('customer'),
        servicePoint: document?.tags?.includes('service-point'),
        insurance: document?.tags?.includes('insurance'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      disableAutoFocus
    >
      <Box sx={styles.modal}>
        <DialogTitle id="alert-dialog-title">Attach to</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={styles.formWrapper}
          >
            <Box sx={styles.fieldWrapper}>
              <Box component="label" htmlFor="customer" sx={styles.label}>
                Customer
              </Box>
              <Box
                component="input"
                sx={{ ...styles.field, ...styles.fullWidth }}
                type="checkbox"
                name="customer"
                {...register('customer')}
              />
            </Box>
            <Box sx={styles.fieldWrapper}>
              <Box component="label" htmlFor="servicePoint" sx={styles.label}>
                Service Point
              </Box>
              <Box
                component="input"
                sx={{ ...styles.field, ...styles.fullWidth }}
                type="checkbox"
                name="servicePoint"
                {...register('servicePoint')}
              />
            </Box>
            <Box sx={styles.fieldWrapper}>
              <Box component="label" htmlFor="insurance" sx={styles.label}>
                Insurance
              </Box>
              <Box
                components="input"
                sx={{ ...styles.field, ...styles.fullWidth }}
                type="checkbox"
                name="insurance"
                {...register('insurance')}
              />
            </Box>

            <DialogActions>
              <Button color="cancel" onClick={onClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                loading={formState.isSubmitting}
              >
                OK
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Box>
    </Modal>
  );
};
export default EditARNMDocumentModal;
