const normalizeColors = (data = []) => {
  if (!data?.length > 0) {
    return [];
  }

  return data
    ?.map((item) => ({ value: item.name.trim(), text: item.name.trim() }))
    ?.sort((a, b) => a.value.localeCompare(b.value));
};

const normalizeDealers = (data = []) => {
  if (!data?.length > 0) {
    return [];
  }

  return data
    ?.map((item) => ({
      value: item.slug,
      text: item.name,
    }))
    ?.sort((a, b) => a.text.localeCompare(b.text));
};

const normalizeVehicleSourcingTypes = (data = []) => {
  if (!data?.length > 0) {
    return [];
  }

  return data
    ?.map((item) => ({
      value: item,
      text: item,
    }))
    ?.sort((a, b) => a.text.localeCompare(b.text));
};

export { normalizeColors, normalizeDealers, normalizeVehicleSourcingTypes };
