export const QUERY_KEYS = {
  GET_BILLING_KEY: 'billing',
  GET_BILLING_TASK_KEY: 'billing-task',
  GET_BILLING_INSTACAR_KEY: 'billing-instacar',
  GET_BILLING_INSTARIDE_KEY: 'billing-instaride',
  GET_BILLING_REFUNDS_KEY: 'billing-refunds',
  GET_BILLING_DAMAGES_KEY: 'billing-damages',
  GET_BILLING_OTHER_KEY: 'billing-other',
  GET_BILLING_ASSIGNEES_KEY: 'billing-assignees',
  GET_CAMPAIGNS_KEY: 'campaigns',
  GET_CAMPAIGN_KEY: 'campaign',
  GET_VEHICLE_BY_INTERNAL_ID_KEY: 'vehicle-by-internal-id',
  GET_DRIVERSE_CAMPAIGNS_BY_PARTNER_ID_KEY: 'driverse-campaigns-by-driverse-id',
  GET_DRIVERSE_CAMPAIGN_BY_ID_KEY: 'driverse-campaign-by-id',
  GET_DRIVERSE_COUPONS_BY_CAMPAIGN_ID_KEY: 'driverse-coupons-by-campaign-id',
  GET_ARNM_REPAIRS_TASKS_KEY: 'arnmRepairs-tasks',
  GET_ARNM_REPAIRS_TASK_KEY: 'arnmRepairs-task',
  GET_ARNM_MAINTENANCE_TASK_KEY: 'arnmMaintenance-task',
  GET_ARNM_MAINTENANCE_ASSIGNEES_KEY: 'arnmMaintenance-assignees',
  GET_ARNM_ACCIDENTS_TASK_KEY: 'arnmAccidents-task',
  GET_ARNM_ACCIDENTS_ASSIGNEES_KEY: 'arnmAccidents-assignees',
  GET_ORDERS_KEY: 'orders-listing',
  GET_LOGGER_KEY: 'error-list',
  GET_AGENTS_KEY: 'agents',
  GET_PARTNERS_KEY: 'partners',
  GET_USERS_KEY: 'users-list',
  GET_PERMISSIONS_KEY: 'permissions',
  GET_CONFIGURATION_KEY: 'configuration',
  GET_ROADSIDE_ASSISTANCE_KEY: 'roadside-assistance',
  GET_ROADSIDE_ASSISTANCES_KEY: 'roadside-assistances',
  GET_RESERVATION_KEY: 'reservation',
  GET_RESERVATION_HISTORY_KEY: 'reservation-history',
  GET_RESERVATION_ASSIGNEES_KEY: 'reservation-assignees',
  GET_RESERVATIONS_KEY: 'reservations',
  GET_RESERVATIONS_ENUMS_KEY: 'reservations-enums',
  GET_SERVICE_POINT_KEY: 'service-point',
  GET_SERVICE_POINTS_KEY: 'service-points',
  GET_SERVICE_POINTS_ENUMS_KEY: 'service-points-enums',
  GET_DEFLEET_KEY: 'defleet',
  GET_DEFLEET_ASSIGNEES_KEY: 'defleet-assignees',
  GET_DEFLEET_TASK_KEY: 'defleet-task',
  GET_DEFLEET_USER_KEY: 'defleet-user',
  GET_TEAMS_KEY: 'teams',
  GET_TEAM_KEY: 'team',
  GET_INSURANCE_KEY: 'insurance',
  GET_INSURANCES_KEY: 'insurances',
  GET_PRODUCTS_KEY: 'products',
  GET_VENDORS_KEY: 'vendors',
  GET_SELL_REQUESTS_KEY: 'sell-requests',
  GET_GREEN_CARD_KEY: 'green-card',
  GET_GREEN_CARD_TASK_KEY: 'green-card-task',
  GET_SERVICE_VEHICLE_KEY: 'service-vehicle',
  GET_SERVICE_VEHICLES_KEY: 'service-vehicles',
  GET_SERVICE_VEHICLES_TYPES_KEY: 'service-vehicles-types',
  GET_TRAFFIC_OFFENSES_KEY: 'traffic-offenses',
  GET_TRAFFIC_OFFENSES_TASK_KEY: 'traffic-offenses-task',
  GET_TRAFFIC_OFFENSES_ASSIGNEES_KEY: 'traffic-offenses-assignees',
  GET_TRAFFIC_OFFENSES_SERVICE_POINTS_KEY: 'traffic-offenses-service-points',
  GET_ORDER_KEY: 'order',
  GET_ORDER_TRANSACTIONS_KEY: 'order-transactions',
  GET_SUBSCRIPTION_KEY: 'subscription',
  GET_LINKED_SUBSCRIPTIONS_WITH_SUB_ID_KEY: 'linked-subscriptions-with-sub-id',
  GET_LINKED_SUBSCRIPTIONS_WITHOUT_SUB_ID_KEY:
    'linked-subscriptions-without-sub-id',
  GET_SUBSCRIPTION_HISTORY_KEY: 'subscription-history',
  GET_SUBSCRIPTIONS_KEY: 'subscriptions',
  GET_EXPORT_DATA_KEY: 'export-data',
  GET_MAILBOX_THREAD: 'mailbox-thread',
  GET_MAIL: 'single-mail',
  GET_MAIL_HISTORY: 'single-mail-history',
  GET_ARNM_MAINTENANCE_TASKS_KEY: 'maintenance-tasks',
  GET_BOOKINGS_KEY: 'bookings',
  GET_SUBSCRIPTIONS_ENUMS_KEY: 'subscriptions-enums',
  GET_VIDEOS_KEY: 'videos',
  GET_FLEET_SOURCING_TYPES: 'fleet-sourcing-types',
  GET_FLEET_DEALERS: 'fleet-dealers',
  GET_FLEET_COLORS: 'fleet-colors',
};
