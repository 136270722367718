import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { showError } from '@utils';
import { QUERY_KEYS } from 'constants/queryKeys';
import { videosServices } from '@services';
import { normalizeVideos } from './utils';

export const useVideosOperations = () => {
  const videosResponse = useQuery([QUERY_KEYS.GET_VIDEOS_KEY], () =>
    videosServices.getVideos({ pageSize: 1000 })
  );

  useEffect(() => {
    if (videosResponse.error) {
      showError(videosResponse.error);
    }
  }, [videosResponse.error]);

  const videos = normalizeVideos(videosResponse?.data?.data || []);

  return {
    loading: videosResponse?.isLoading,
    videos,
    getVideos: videosResponse.refetch,
  };
};
