import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { selectSubscriptionsState } from '@store';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { subscriptionsServices } from '@services';
import { FILTER_RULE_ENUMS } from '@constants';
import { handleSubscription } from './utils';

export const useSubscriptionOperations = (fetchLinkedSubs = true) => {
  const { getSubscriptionHasErrors, getSubscriptionErrorMessage } = useSelector(
    selectSubscriptionsState
  );
  const router = useRouter();
  const subscriptionId = router.query?.subscriptionId;

  const enumsResponse = useQuery([QUERY_KEYS.GET_SUBSCRIPTIONS_ENUMS_KEY], () =>
    subscriptionsServices.getSubscriptionsEnums()
  );

  const subscriptionResponse = useQuery(
    [QUERY_KEYS.GET_SUBSCRIPTION_KEY, subscriptionId],
    () => subscriptionsServices.getSubscriptionById(subscriptionId),
    {
      enabled: !!subscriptionId && enumsResponse?.isFetched,
    }
  );

  const standardSubscriptionId =
    subscriptionResponse?.data?.data?.standardSubscriptionId;

  const linkedSubscriptionsWithStandardSubIdResponse = useQuery(
    [
      QUERY_KEYS.GET_LINKED_SUBSCRIPTIONS_WITH_SUB_ID_KEY,
      standardSubscriptionId,
    ],
    () => subscriptionsServices.getSubscriptionById(standardSubscriptionId),
    {
      enabled: fetchLinkedSubs && !!standardSubscriptionId,
    }
  );

  const linkedSubscriptionsWithoutStandardSubIdResponse = useQuery(
    [
      QUERY_KEYS.GET_LINKED_SUBSCRIPTIONS_WITHOUT_SUB_ID_KEY,
      standardSubscriptionId,
    ],
    () =>
      subscriptionsServices.getSubscriptions({
        payload: {
          type: FILTER_RULE_ENUMS.ComparableRuleType,
          field: 'standardSubscriptionId',
          operator: 0,
          page: 1,
          value: subscriptionId,
        },
        params: {
          page: 1,
          pageSize: 500,
        },
      }),
    {
      enabled:
        fetchLinkedSubs &&
        standardSubscriptionId !== undefined &&
        !standardSubscriptionId,
    }
  );

  const handleStandardSubscriptionId = () => {
    const linkedSubscriptions = {};
    if (standardSubscriptionId) {
      // is child
      linkedSubscriptions.data = [
        linkedSubscriptionsWithStandardSubIdResponse?.data?.data,
      ];
    } else {
      // is parent
      linkedSubscriptions.data = [
        linkedSubscriptionsWithoutStandardSubIdResponse?.data?.data,
      ];
    }

    return linkedSubscriptions;
  };

  const linkedSubscriptionsData = handleStandardSubscriptionId();

  const subscription = handleSubscription(
    subscriptionResponse?.data?.data || {},
    linkedSubscriptionsData?.data || [],
    enumsResponse?.data?.data?.reasons_of_returning || []
  );

  useEffect(() => {
    if (getSubscriptionErrorMessage) {
      showError(getSubscriptionErrorMessage);
    }
  }, [getSubscriptionErrorMessage]);

  return {
    loading:
      subscriptionResponse?.isFetching ||
      linkedSubscriptionsWithStandardSubIdResponse?.isFetching ||
      linkedSubscriptionsWithoutStandardSubIdResponse?.isFetching,
    subscriptionId,
    subscription,
    getSubscriptionHasErrors,
    getSubscriptionErrorMessage,
    getSubscription: subscriptionResponse?.refetch,
  };
};
