import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';
import { omit } from 'lodash';

export const sellVehiclesPayload = (data) => {
  return {
    id: data.id,
    vehicleInternalId: data.vehicleInternalId,
    plate: data.plate,
    vin: data.vin,
    skuId: data?.skuId ? `IC${data?.skuId}` : null,
    price: `${data.price}`,
    // initialPrice: data.initialPrice,
    transferOfOwnershipFee: data.transferOfOwnershipFee,
    purchasePrice: data.purchasePrice,
    nbv: data.nbv,
    minimumPrice: data.minimumPrice,
    videoUrl: data.videoUrl,
    serviceInfo: data.serviceInfo || [],
    // vehicleOwnership: data?.vehicleOwnership,
    sku: {
      brand: data.sku.brand,
      brandSlug: data.sku.brandSlug,
      model: data.sku.model,
      modelSlug: data.sku.modelSlug,
      edition: data.sku.edition,
      year: Number(data.sku.year),
      engine: {
        displacement: Number(data.sku.engine.displacement),
        powerHp: Number(data.sku.engine.powerHp),
        fuelType: Number(data.sku.engine.fuelType),
        type: Number(data.sku.engine.type),
        fuelEconomy: Number(data.sku.engine.fuelEconomy),
        fuelCapacity: Number(data.sku.engine.fuelCapacity),
        emissions: Number(data.sku.engine.emissions),
        topSpeed: data.sku.engine.topSpeed
          ? parseInt(data.sku.engine?.topSpeed, 10)
          : 0,
        torque: data.sku.engine.torque
          ? parseInt(data.sku.engine.torque, 10)
          : 0,
        euroClass: data.sku.engine.euroClass
          ? parseInt(data.sku.engine.euroClass, 10)
          : 0,
        autonomy: Number(data.sku.engine.autonomy),
        batteryChargeTime: data.sku.engine.batteryChargeTime
          ? Number(data.sku.engine.batteryChargeTime)
          : 0,
        batterySize: data.sku.engine.batterySize,
        acceleration: Number(data.sku.engine.acceleration),
        numberOfGears: Number(data.sku.engine.numberOfGears),
      },
      body: {
        type: Number(data.sku.body.type),
        size: Number(data.sku.body.size) || null,
        seatsNumber: Number(data.sku.body.seatsNumber),
        doorsNumber: Number(data.sku.body.doorsNumber),
        airbagsNumber: Number(data.sku.body.airbagsNumber),
        height: Number(data.sku.body.height),
        weight: Number(data.sku.body.weight),
        interiorType: data.sku.body.interiorType || null,
        trunkCapacity: data.sku.body.trunkCapacity
          ? Number(data.sku.body.trunkCapacity)
          : 0,
        length: data.sku.body.length ? parseInt(data.sku.body.length, 10) : 0,
        width: data.sku.body.width ? parseInt(data.sku.body.width, 10) : 0,
      },
      transmission: Number(data.sku.transmission),
      category: Number(data.sku.category),
      tireSize: data.sku.tireSize,
      equipment: {
        multimedia: {
          'el-GR': data?.sku?.equipment?.multimedia?.['el-GR']
            ? data?.sku?.equipment?.multimedia?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.sku?.equipment?.multimedia?.['en-US']
            ? data?.sku?.equipment?.multimedia?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        exterior: {
          'el-GR': data?.sku?.equipment?.exterior?.['el-GR']
            ? data?.sku?.equipment?.exterior?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.sku?.equipment?.exterior?.['en-US']
            ? data?.sku?.equipment?.exterior?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        interior: {
          'el-GR': data?.sku?.equipment?.interior?.['el-GR']
            ? data?.sku?.equipment?.interior?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.sku?.equipment?.interior?.['en-US']
            ? data?.sku?.equipment?.interior?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        comfort: {
          'el-GR': data?.sku?.equipment?.comfort?.['el-GR']
            ? data?.sku?.equipment?.comfort?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.sku?.equipment?.comfort?.['en-US']
            ? data?.sku?.equipment?.comfort?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        safety: {
          'el-GR': data?.sku?.equipment?.safety?.['el-GR']
            ? data?.sku?.equipment?.safety?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.sku?.equipment?.safety?.['en-US']
            ? data?.sku?.equipment?.safety?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
      },
    },
    mileageFromOdometer: data.mileageFromOdometer,
    properties: {
      color: data.properties.color,
      roofColor: null,
      interiorColor: null,
    },
    additionalInfo: {
      'el-GR': data?.additionalInfo?.['el-GR'] || '',
      'en-US': data?.additionalInfo?.['en-US'] || '',
    },
    availability: data.availability,
    exportCarGr: data.exportCarGr || false,
  };
};

export const sellVehiclesEditPayload = (data) => {
  return {
    id: data.id,
    vin: data.vin,
    plate: data.plate,
    published: data.published,
    price: `${data.price}`,
    transferOfOwnershipFee: data.transferOfOwnershipFee,
    purchasePrice: data.purchasePrice,
    nbv: data.nbv,
    minimumPrice: data.minimumPrice,
    videoUrl: data.videoUrl,
    serviceInfo: data.serviceInfo || [],
    mileageFromOdometer: data.mileageFromOdometer,
    properties: {
      color: data.properties.color,
      roofColor: null,
      interiorColor: null,
    },
    availability: data.availability,
    vehicleOwnership: data?.vehicleOwnership,
    sku: {
      brand: data.sku.brandSlug,
      model: data.sku.modelSlug,
      edition: data.sku.edition,
      year: Number(data.sku.year),
      engine: {
        displacement: Number(data.sku.engine.displacement),
        powerHp: Number(data.sku.engine.powerHp),
        fuelType: Number(data.sku.engine.fuelType),
        type: Number(data.sku.engine.type),
        fuelEconomy: Number(data.sku.engine.fuelEconomy),
        fuelCapacity: Number(data.sku.engine.fuelCapacity),
        emissions: Number(data.sku.engine.emissions),
        topSpeed: data.sku.engine.topSpeed
          ? parseInt(data.sku.engine?.topSpeed, 10)
          : 0,
        torque: data.sku.engine.torque
          ? parseInt(data.sku.engine.torque, 10)
          : 0,
        euroClass: data.sku.engine.euroClass
          ? parseInt(data.sku.engine.euroClass, 10)
          : 0,
        autonomy: Number(data.sku.engine.autonomy),
        batteryChargeTime: data.sku.engine.batteryChargeTime
          ? Number(data.sku.engine.batteryChargeTime)
          : 0,
        batterySize: data.sku.engine.batterySize,
        acceleration: Number(data.sku.engine.acceleration),
        numberOfGears: Number(data.sku.engine.numberOfGears),
      },
      body: {
        type: Number(data.sku.body.type),
        size: Number(data.sku.body.size) || null,
        seatsNumber: Number(data.sku.body.seatsNumber),
        doorsNumber: Number(data.sku.body.doorsNumber),
        airbagsNumber: Number(data.sku.body.airbagsNumber),
        height: Number(data.sku.body.height),
        weight: Number(data.sku.body.weight),
        interiorType: data.sku.body.interiorType || null,
        trunkCapacity: data.sku.body.trunkCapacity
          ? Number(data.sku.body.trunkCapacity)
          : 0,
        length: data.sku.body.length ? parseInt(data.sku.body.length, 10) : 0,
        width: data.sku.body.width ? parseInt(data.sku.body.width, 10) : 0,
      },
      transmission: Number(data.sku.transmission),
      category: Number(data.sku.category),
      tireSize: data.sku.tireSize,
      equipment: {
        multimedia: {
          'el-GR': data?.sku?.equipment?.multimedia?.['el-GR']
            ? data?.sku?.equipment?.multimedia?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.sku?.equipment?.multimedia?.['en-US']
            ? data?.sku?.equipment?.multimedia?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        exterior: {
          'el-GR': data?.sku?.equipment?.exterior?.['el-GR']
            ? data?.sku?.equipment?.exterior?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.sku?.equipment?.exterior?.['en-US']
            ? data?.sku?.equipment?.exterior?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        interior: {
          'el-GR': data?.sku?.equipment?.interior?.['el-GR']
            ? data?.sku?.equipment?.interior?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.sku?.equipment?.interior?.['en-US']
            ? data?.sku?.equipment?.interior?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        comfort: {
          'el-GR': data?.sku?.equipment?.comfort?.['el-GR']
            ? data?.sku?.equipment?.comfort?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.sku?.equipment?.comfort?.['en-US']
            ? data?.sku?.equipment?.comfort?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
        safety: {
          'el-GR': data?.sku?.equipment?.safety?.['el-GR']
            ? data?.sku?.equipment?.safety?.['el-GR']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
          'en-US': data?.sku?.equipment?.safety?.['en-US']
            ? data?.sku?.equipment?.safety?.['en-US']
                ?.split('<br>')
                ?.map((item) => item.trim())
            : [],
        },
      },
    },
    additionalInfo: {
      'el-GR': data?.additionalInfo?.['el-GR'] || '',
      'en-US': data?.additionalInfo?.['en-US'] || '',
    },
    hideDisclaimer: data.hideDisclaimer,
    exportCarGr: data.exportCarGr || false,
  };
};

export const getSellVehiclesRequestPayload = (
  payload = {},
  modalFiltersRules = null
) => {
  const fields =
    omit(payload, ['page', 'pageSize', 'order', 'orderBy', 'searchTerm']) || {};
  const mapFields = {
    status: 'published',
    brand: 'sku.brand',
    model: 'sku.model',
    category: 'sku.category',
  };
  const booleanValues = {
    published: true,
    unPublished: false,
  };
  const booleanFields = ['status'];
  const multyFilterStringFields = ['brand', 'model'];
  const multyFilterNumberFields = ['category'];

  const { searchTerm } = payload;

  const _getValue = ({ field }) => {
    if (multyFilterStringFields.includes(field)) {
      return payload[field]?.split(',');
    }
    if (multyFilterNumberFields.includes(field)) {
      return payload[field]?.split(',').map((item) => Number(item));
    }

    return booleanFields.includes(field)
      ? booleanValues[payload[field]]
      : payload[field];
  };
  const _getType = ({ field }) => {
    if (
      multyFilterStringFields.includes(field) ||
      multyFilterNumberFields?.includes(field)
    ) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }
    if (booleanFields.includes(field)) {
      return FILTER_RULE_ENUMS.ComparableRuleType;
    }
    return FILTER_RULE_ENUMS.ComparableRuleType;
  };

  const value = [
    ...Object.keys(fields).map((field) => ({
      field: mapFields[field],
      operator: FILTER_OPERATOR_ENUMS['='],
      type: _getType({ field }),
      value: _getValue({ field }),
    })),
  ];
  value.push(...(modalFiltersRules?.value || []));

  if (searchTerm) {
    const filedsToSearch = [
      'vehicleInternalId',
      'plate',
      'vin',
      'skuId',
      'internalId',
    ];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: filedsToSearch.map((field) => ({
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        operator: FILTER_OPERATOR_ENUMS.contains,
        field,
        value: searchTerm,
      })),
    };
    value.push(searchRule);
  }

  if (value.length) {
    return {
      type: FILTER_RULE_ENUMS.AndRuleType,
      field: null,
      operator: null,
      value,
    };
  }

  return {
    type: FILTER_RULE_ENUMS.EmptyRuleType,
  };
};
