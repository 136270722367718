import dynamic from 'next/dynamic';

const CampaignsGrid = dynamic(() => import('./CampaignsGrid/CampaignsGrid'));
const CampaignContent = dynamic(() =>
  import('./CampaignContent/CampaignContent')
);

const CampaignContentTitle = dynamic(() =>
  import('./CampaignContentTitle/CampaignContentTitle')
);
const CampaignUploadedImage = dynamic(() =>
  import('./CampaignUploadedImage/CampaignUploadedImage')
);
const CancelCampaign = dynamic(() => import('./CancelCampaign/CancelCampaign'));

export {
  CampaignsGrid,
  CampaignContent,
  CampaignContentTitle,
  CampaignUploadedImage,
  CancelCampaign,
};
