/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { getUrl, showError } from '@utils';
import { boardsServices } from '@services';
import { BOARDS, BOARDS_ID, LIMIT } from '@constants';
import { URLS } from 'layouts/DrawerLayout/constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import { useQuery } from 'react-query';
import {
  getGreenCardColumns,
  getGreenCardPayload,
  handleGreenCardData,
} from './utils';

const pageSize = LIMIT;

export const useGreenCardOperations = (fetchOnMount) => {
  const router = useRouter();
  const page = router.query?.page || '1';
  const orderBy = router.query?.orderBy || 'createdAt';
  const order = router.query?.order || 'desc';
  const params = {
    page,
    orderBy,
    order,
  };

  const { isReady } = router;

  const greenCardResponse = useQuery(
    [QUERY_KEYS.GET_GREEN_CARD_KEY, params],
    () => {
      const payload = getGreenCardPayload({
        orderBy,
        order,
        page,
        pageSize,
        boardId: BOARDS_ID.GREEN_CARD,
        boardType: BOARDS.GREEN_CARD,
      });
      return boardsServices.getBoardsFilters(payload);
    },
    { enabled: isReady && fetchOnMount }
  );

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel }, URLS.greenCard);
    router.push(url);
  };

  const handlePageChange = (newPage) => {
    const url = getUrl({ ...router.query, page: newPage }, URLS.greenCard);

    router.push(url);
  };

  useEffect(() => {
    if (greenCardResponse?.error) {
      showError(greenCardResponse.error);
    }
  }, [greenCardResponse?.error]);

  const greenCard = handleGreenCardData(greenCardResponse?.data?.data || []);
  const columns = getGreenCardColumns(greenCard?.tasks || []);

  return {
    page,
    total: greenCardResponse?.data?.meta?.total,
    pageSize,
    loading: greenCardResponse?.isLoading,
    tasks: greenCard?.tasks || [],
    columns,
    hasErrors: greenCardResponse?.isError,
    errorMessage: greenCardResponse?.error,
    getGreenCard: greenCardResponse.refetch,
    handlePageChange,
    handleSortBy,
  };
};
