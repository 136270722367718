import theme from 'config/theme';

export default {
  instruction: {
    marginTop: '0.25rem',
    color: theme.palette.darkGray.main,
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  error: {
    color: theme.palette.error.main,
  },
  formWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(200px, 1fr))',
    gridTemplateRows: 'auto',
    alignItems: 'start',
    gap: '24px',
    '@media (min-width: 1280px)': {
      gridTemplateColumns: 'repeat(4, minmax(200px, 1fr))',
      gridTemplateRows: 'auto',
    },
  },
  sectionWrapper: {
    padding: '8px 16px',
    flexDirection: 'column',
    backgroundColor: theme.palette.white.paper,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '8px',
  },
  inputWrapperStyle: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '16px',
  },
  sku: {
    '@media (min-width: 1280px)': {
      gridColumn: '1',
      gridRow: '1 / 2',
    },
  },
  branding: {
    '@media (min-width: 1280px)': {
      gridColumn: '2',
      gridRow: '1 / 2',
    },
  },
  engine: {
    '@media (min-width: 1280px)': {
      gridColumn: '2',
      gridRow: '2 / 5',
    },
  },
  exterior: {
    '@media (min-width: 1280px)': {
      gridColumn: '1',
      gridRow: '2 / 4',
    },
  },
  interior: {
    '@media (min-width: 1280px)': {
      gridColumn: '1',
      gridRow: '4 / 5',
    },
  },
  maintenance: {
    '@media (min-width: 1280px)': {
      gridColumn: '1',
      gridRow: '5 / 6',
    },
  },
  miscellaneous: {
    '@media (min-width: 1280px)': {
      gridColumn: '3',
      gridRow: '1 / 4',
    },
  },
  options: {
    '@media (min-width: 1280px)': {
      gridColumn: '4',
      gridRow: '1 / 3',
    },
  },
  additional: {
    '@media (min-width: 1280px)': {
      gridColumn: '3',
      gridRow: '4 / 5',
    },
  },
  pricing: {
    '@media (min-width: 1280px)': {
      gridColumn: '1 / 5',
    },
  },
  documents: {
    '@media (min-width: 1280px)': {
      gridColumn: '1 / 5',
    },
  },
  equipment: {
    '@media (min-width: 1280px)': {
      gridColumn: '4',
      gridRow: '3 / 6',
    },
  },
};
