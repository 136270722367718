import { request } from '@utils';

const getVideos = async () => {
  const results = await request.get(`/v1/videos`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getVideo = async (videoId) => {
  const results = await request.get(`/v1/videos/${videoId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const createVideo = async (payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(`/v1/videos`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const updateVideo = async (videoId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(`/v1/videos/${videoId}`, params);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteVideo = async (videoId) => {
  const results = await request.remove(`/v1/videos/${videoId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};
export { getVideos, getVideo, createVideo, updateVideo, deleteVideo };
