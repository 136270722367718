import { useState, useCallback, useEffect } from 'react';

import { formatDate, showError } from '@utils';
import { fleetVehiclesServices } from '@services';
import { useFlags } from '@components';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import { getTabs } from 'components/vehicles/FleetVehicleMenu/utils';
import { useRouter } from 'next/router';

const getFieldFormSheme = (data) => ({
  sections: [
    {
      title: '',
      fontWeight: 'bold',
      sectionWrapperStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        width: '100%',
      },
      rows: {
        title: '',
        rowWrapperStyle: {
          padding: '12px 0px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        },
        data: [
          {
            title: '',
            columnWrapperStyle: {
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: 2,
              gap: 2,
            },

            data: [data],
          },
        ],
      },
    },
  ],
});

export const useFleetVehicleHandlers = ({
  vehicle,
  vehicleId,
  getVehicle,
  initialize,
  getFleetVehicleChangelog,
}) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [formSceme, setFormScheme] = useState(null);
  const [submiting, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const { vehicleImageUpdate } = useFlags();
  const tabs = getTabs(vehicle?.stage?.newStageId?.defaultValue);
  const initialActiveTab = tabs.findIndex(
    (tab) => tab.key === (router.query?.tab || 'overview')
  );
  const [activeTab, setTab] = useState(initialActiveTab);

  const handleToggleModal = () => {
    setOpen(!open);
    if (open) {
      setFormScheme(null);
    }
  };

  const handleToggleModalDetails = () => {
    setOpenDetails(!openDetails);
    if (openDetails) {
      setFormScheme(null);
    }
  };

  const handleEditStageField = useCallback(
    (field, key) => {
      const inputField = {};
      inputField[key] = { ...field };
      setTitle(field?.title || field?.label);
      const sheme = getFieldFormSheme(inputField);
      setFormScheme(sheme);
      handleToggleModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab]
  );

  const handleEditField = useCallback(
    (field, key) => {
      const inputField = {};
      inputField[key] = { ...field };
      setTitle(field?.title || field?.label);
      const sheme = getFieldFormSheme(inputField);

      setFormScheme(sheme);
      handleToggleModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab]
  );

  const handleEditDetails = useCallback(() => {
    setTitle('Update Details');
    const detailsData = Object.entries(vehicle?.details ?? {}).reduce(
      (acc, [key, value]) => {
        if (value?.canEdited !== true) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    const sheme = getFieldFormSheme(detailsData);

    setFormScheme(sheme);
    handleToggleModalDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, vehicle?.details]);

  const handleImageClick = (url) => {
    if (vehicleImageUpdate !== 'true') {
      return;
    }
    setTitle(url ? 'Update Image' : 'Add Image');
    const sheme = getFieldFormSheme({
      url: {
        label: 'URL',
        type: 'text',
        placeholder: '',
        defaultValue: url,
        rules: {
          required: '* This field is required',
          pattern: {
            value:
              /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            message: 'The url is not valid',
          },
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
    });

    setFormScheme(sheme);
    handleToggleModal();
  };

  const handleSubmitField = async (form) => {
    try {
      setLoading(true);
      let field = '';

      if ('skuId' in form) {
        field = 'sku';
      }
      if ('vin' in form) {
        field = 'vin';
      }
      if ('color' in form) {
        field = 'color';
      }
      if ('condition' in form) {
        field = 'condition';
      }
      if ('description' in form) {
        field = 'description';
      }
      if ('insuranceId' in form) {
        field = 'insurance';
      }
      if ('roadsideAssistanceId' in form) {
        field = 'roadsideAssistance';
      }
      if ('url' in form && !vehicle?.images?.[0]?.id) {
        await fleetVehiclesServices?.postVehicleImage(vehicleId, form);
      } else if ('url' in form) {
        await fleetVehiclesServices?.updateVehicleImage(
          vehicleId,
          vehicle?.images?.[0]?.id,
          form
        );
      } else if (field === 'color') {
        await fleetVehiclesServices?.updateVehicle(vehicleId, {
          properties: { color: form?.color },
        });
      } else if ('newStageId' in form) {
        await fleetVehiclesServices?.postProcurementEvent(vehicleId, form, 6);
      } else {
        await fleetVehiclesServices?.updateVehicle(vehicleId, form);
      }
      await getVehicle();

      handleToggleModal();
    } catch (error) {
      let currentError;
      if (vehicle?.stage?.newStageId?.defaultValue === 8) {
        currentError = error;
      }
      showError(currentError);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitDetails = async (form) => {
    try {
      setLoading(true);
      form.procurement = {
        insuranceRenewalDate: formatDate(
          form?.insuranceRenewalDate,
          'YYYY-MM-DD'
        ),
      };
      delete form?.insuranceRenewalDate;
      delete form?.createdAt;
      delete form?.updatedAt;
      await fleetVehiclesServices?.updateVehicle(vehicleId, form);
      await getVehicle();
      handleToggleModalDetails();
    } catch (error) {
      let currentError = GENERIC_ERROR_MESSAGE;
      if (typeof error === 'string' || error instanceof String) {
        currentError = error;
      }

      showError(currentError);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => getVehicle({ loading: true });

  useEffect(() => {
    return () => {
      initialize();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeTab === 2) {
      getFleetVehicleChangelog(vehicleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, vehicleId]);

  return {
    handleImageClick,
    handleSubmitDetails,
    handleSubmitField,
    handleEditDetails,
    handleEditField,
    handleEditStageField,
    handleRefresh,
    handleToggleModalDetails,
    handleToggleModal,
    formSceme,
    title,
    submiting,
    openDetails,
    open,
    activeTab,
    setTab,
  };
};

export default useFleetVehicleHandlers;
