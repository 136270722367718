export const INITIAL_VALUES = {
  type: '',
  name: '',
  vehicleInternalId: '',
  internalId: '',
  plate: '',
  vin: '',
  skuId: '',
  published: false,
  price: '',
  transferOfOwnershipFee: '',
  purchasePrice: '',
  nbv: '',
  minimumPrice: '',
  vehicleOwnership: '',
  sku: {
    brand: '',
    brandSlug: '',
    model: '',
    modelSlug: '',
    edition: '',
    year: '',
    engine: {
      displacement: '',
      powerHp: '',
      fuelType: '',
      type: '',
      fuelEconomy: '',
      emissions: '',
      fuelCapacity: '',
      topSpeed: '',
      torque: '',
      euroClass: '',
      autonomy: '',
      speed: '',
      batterySize: '',
      tireSize: '',
      batteryChargeTime: '',
      acceleration: '',
      numberOfGears: '',
    },
    body: {
      type: '',
      size: '',
      seatsNumber: '',
      doorsNumber: '',
      airbagsNumber: '',
      height: '',
      weight: '',
      interiorType: '',
      trunkCapacity: '',
      length: '',
      width: '',
    },
    transmission: '',
    category: '',
    productLine: '',
    tireSize: '',
    equipment: {
      comfort: {
        'el-GR': '',
        'en-US': '',
      },
      exterior: {
        'el-GR': '',
        'en-US': '',
      },
      interior: {
        'el-GR': '',
        'en-US': '',
      },
      multimedia: {
        'el-GR': '',
        'en-US': '',
      },
      safety: {
        'el-GR': '',
        'en-US': '',
      },
    },
  },
  images: [],
  docs: [],
  instastart: '',
  signUpFee: '',
  monthlyKilometers: '',
  tags: [],
  colors: [],
  vendors: [],
  availability: '',
  pricePerKm: '',
  chargePerExtraKm: '',
  retailPrice: '',
  instacarPrice: '',
  videoUrl: '',
  extras: [],
  additionalInfo: {
    'el-GR': '',
    'en-US': '',
  },
  selectedImageId: '',
  comments: '',
  cities: [],
  createdAt: '',
  createdBy: '',
  updatedAt: '',
  updatedBy: '',
  serviceInfo: [],
  mileageFromOdometer: '',
  properties: {
    color: '',
    roofColor: '',
    interiorColor: '',
  },
  exportCarGr: false,
};
