import theme from 'config/theme';

export const BUY_OUT_TASK_STATUSES_NAMES = {
  0: {
    label: 'Backlog',
    styles: {
      backgroundColor: theme.palette.grays.gray200,
      color: theme.palette.grays.gray900,
    },
  },
  4: {
    label: 'Approval',
    styles: {
      backgroundColor: theme.palette.mints.mint200,
      color: theme.palette.mints.mint900,
    },
  },
  5: {
    label: 'Offer',
    styles: {
      backgroundColor: theme.palette.teals.teal200,
      color: theme.palette.teals.teal900,
    },
  },
  7: {
    label: 'Loans',
    styles: {
      backgroundColor: theme.palette.pinks.pink200,
      color: theme.palette.pinks.pink900,
    },
  },
  6: {
    label: 'Waiting Customer Response',
    styles: {
      backgroundColor: theme.palette.purples.purple200,
      color: theme.palette.purples.purple900,
    },
  },
  8: {
    label: 'Buyout Deposit',
    styles: {
      backgroundColor: theme.palette.limes.lime200,
      color: theme.palette.limes.lime900,
    },
  },
  9: {
    label: 'Vehicle Sales',
    styles: {
      backgroundColor: theme.palette.babyBlues.babyBlue200,
      color: theme.palette.babyBlues.babyBlue900,
    },
  },
  10: {
    label: 'Transferred',
    styles: {
      backgroundColor: theme.palette.blues.primaryBlue100,
      color: theme.palette.blues.primaryBlue900,
    },
  },
  3: {
    label: 'Buyout Completed',
    styles: {
      backgroundColor: theme.palette.fukshias.fukshia200,
      color: theme.palette.fukshias.fukshia900,
    },
  },
  11: {
    label: 'Rejected',
    styles: {
      backgroundColor: theme.palette.reds.red200,
      color: theme.palette.reds.red900,
    },
  },
  12: {
    label: 'Cancelled',
    styles: {
      backgroundColor: theme.palette.oranges.orange200,
      color: theme.palette.oranges.orange900,
    },
  },
};

export const BUY_OUT_TASK_STATUSES_ORDER = [0, 4, 5, 7, 6, 8, 9, 10, 3, 11, 12];

export const BUY_OUT_STAGE_OPTIONS = [
  { text: 'Backlog', value: '0' },
  { text: 'Approval', value: '4' },
  { text: 'Offer', value: '5' },
  { text: 'Loans', value: '7' },
  { text: 'Waiting Customer Response', value: '6' },
  { text: 'Buyout Deposit', value: '8' },
  { text: 'Vehicle Sales', value: '9' },
  { text: 'Transferred', value: '10' },
  { text: 'Buyout Completed', value: '3' },
  { text: 'Rejected', value: '11' },
  { text: 'Cancelled', value: '12' },
];

export const BUY_OUT_TASK_STATUSES_SERVER_NAMES = {
  0: 'Backlog',
  4: 'Approval',
  5: 'Offer',
  7: 'Loans',
  6: 'WaitingCustomerResponse',
  8: 'BuyOutDeposit',
  9: 'VehicleSales',
  10: 'Transferred',
  3: 'Done',
  11: 'CustomerRejected',
  12: 'Cancelled',
};

export const BUY_OUT_STATUSES_NAMES_MAP = {
  Backlog: 0,
  Approval: 4,
  Offer: 5,
  Loans: 7,
  WaitingCustomerResponse: 6,
  BuyOutDeposit: 8,
  VehicleSales: 9,
  Transferred: 10,
  Done: 3,
  CustomerRejected: 11,
  Cancelled: 12,
};

export const BUY_OUT_LABEL_FIELD_NAMES = {
  2: '',
};

export const BUY_OUT_REASONS_OF_REJECTION = [
  {
    text: 'Price too low',
    value: 'Price too low',
  },
  {
    text: 'Price too high',
    value: 'Price too high',
  },
  {
    text: 'Data inaccuracy',
    value: 'Data inaccuracy',
  },
  {
    text: 'Vehicle not for sale',
    value: 'Vehicle not for sale',
  },
  {
    text: 'Other (please specify)',
    value: 'Other',
  },
];

export const BUY_OUT_CLIENT_REASONS_OF_REJECTION = [
  {
    text: 'The price is beyond my budget',
    value: 'The price is beyond my budget',
  },
  {
    text: 'Not interested at this time',
    value: 'Not interested at this time',
  },
  {
    text: 'Found another option',
    value: 'Found another option',
  },
  {
    text: 'Will remain on leasing',
    value: 'Will remain on leasing',
  },
  {
    text: 'Conducted market research',
    value: 'Conducted market research',
  },
  {
    text: 'Bank rejection',
    value: 'Bank rejection',
  },
  {
    text: 'Other',
    value: 'Other',
  },
];

export const BUY_OUT_TASK_FIELD_NAMES = {
  name: 'Title',
  subscriberName: 'Subscriber Name',
  subscriberSurname: 'Subscriber Surname',
  promisedReturnDate: 'Promised Return Date',
  plannedUserReturnDate: 'Planned User Return Date',
  dateAndTime: 'Date & Time',
  userId: 'User ID',
  carId: 'Car ID',
  pipedriveId: 'Pipedrive ID',
  trelloBillingCardId: 'Trello Billing Card ID',
  vehicleModel: 'Vehicle Model',
  vehicleBrand: 'Vehicle Brand',
  trelloDriversCardId: 'Trello Drivers Card ID',
  time: 'Time of Return',
  trelloOpsCardId: 'Τrello Ops Card ID',
  location: 'Location of Return',
  plate: 'Plate',
  label: 'Tags',
  status: 'Status',
  tags: 'Tag',
  returnedDate: 'Return Date',
  billingComments: 'Billing Comments',
  billingChargeType: 'Charge',
  approvalStatus: 'Approval Status',
  bank: 'Bank',
  commercialAssessment: 'Commercial Assessment',
  comparablePrice: 'Comparable Price',
  customerRejectedReason: 'Customer Rejected Reason',
  dateOfDeposit: 'Date of Deposit',
  depositConfirmation: 'Deposit Confirmation',
  liftingOfWithholding: 'Lifting of Withholding',
  downpayment: 'Downpayment',
  extraCap: 'Extra Cap',
  finalPrice: 'Final Price',
  financedAmount: 'Financed Amount',
  certificateOfUnpaidTrafficFees: 'Certificate of Unpaid Traffic Fees',
  invoice: 'Invoice',
  kteo: 'KTEO',
  purchaseInvoice: 'Purchase Invoice',
  vehicleTransferAuthorization: 'Vehicle Transfer Authorization',
  loan: 'Loan',
  loanRequestStatus: 'Loan Request Status',
  managingCosts: 'Managing Costs',
  marginPercentage: 'Margin Percentage',
  minimumPrice: 'Minimum Price',
  nbv: 'NBV',
  offerExpirationDate: 'Offer Expiration Date',
  offerRequestDate: 'Offer Request Date',
  offerSentDate: 'Offer Sent Date',
  payoutClientApproval: 'Payout Client Approval',
  payoutMonthlyInstallment: 'Payout Monthly Installment',
  payoutPlanMonths: 'Payout Plan Months',
  reasonsOfRejection: 'Reasons of Rejection',
  disableSecurity: 'Disable Security',
  scheduleDeliveryOfLicenseAndSecondKey:
    'Schedule Delivery of License and Second Key',
  vehicleDefleeted: 'Vehicle Defleeted',
  transferOfOwnershipFee: 'Transfer of Ownership Fee',
  finalPriceRejectionReason: 'Rejection Reason',
  administrativeExpenses: 'Administrative Expenses',
  interestRate: 'Interest Rate (%)',
  loanAssignee: 'Loan Assignee',
  loanContractId: 'Loan Contract ID',
  installments: 'Installments',
  months: 'Months',
  amount: 'Amount',
  selected: 'Selected',
  copyOfLicenseSending: 'Copy of License Sending',
  loanDisbursed: 'Loan Disbursed',
  vehiclePurchasePrice: 'Vehicle Purchase Price',
  status_updated_at: 'Status updated at',
  clientReasonOfRejection: 'Client Reason of Rejection',
  clientReasonOfRejectionDesc: 'Client Rejection Description',
};
