import {
  LOCATION_ASPROPIRGOS,
  LOCATION_GERAKAS,
  LOCATION_ATHENS_INTERNATIONAL_AIRPORT,
  LOCATION_KIFISIA,
  LOCATION_ARGYROUPOLI,
} from 'components/subscriptions/constants';

/* eslint-disable no-useless-escape */
export * from './orders';
export * from './users';
export const LIMIT = 40;
export const CURRENCY = 'EUR';
export const GENERIC_ERROR_MESSAGE =
  'We are having some issues, we will fix them ASAP';
export const SERVICE_POINTS_PAGE_SIZE = 1000;
export const TENANT_NAMES = {
  0: 'instacar',
  1: 'instaride / kineo',
};

export const DEFAULT_STREET_NAME = 'Πηγάσου';
export const DEFAULT_LOCATION = 'instacar point - Pigasou';
export const DEFAULT_STREET_NUMBER = '10';
export const DEFAULT_LOCALITY = 'Μαρούσι';
export const DEFAULT_POSTAL_CODE = '151 25';
export const DEFAULT_REGION = 'Περιφερειακή ενότητα Βορείου Τομέα Αθηνών';
export const DEFAULT_COUNTRY = 'Ελλάδα';
export const DEFAULT_LAT = 38.0325463;
export const DEFAULT_LNG = 23.8052333;

export const PUBLIC_PATHS = ['/login'];

export const GRID_COLUMN_DEFAULT_PROPERTIES = {
  display: 'flex',
  minWidth: 80,
  headerAlign: 'left',
  align: 'left',
};

export const HANDOVER_CARS_TYPE = {
  DELIVERY: 0,
  RETURNS: 1,
};

export const HANDOVER_CARS_SIDE = {
  FRONT: 0,
  BACK: 1,
  LEFT: 2,
  RIGHT: 3,
  ABOVE: 4,
  INTERIOR: 5,
};

export const HANDOVER_CARS_LOCATIONS = {
  0: DEFAULT_LOCATION,
  1: LOCATION_KIFISIA,
  2: LOCATION_GERAKAS,
  3: LOCATION_ASPROPIRGOS,
  4: LOCATION_ARGYROUPOLI,
  5: 'instadelivery',
  6: 'Athens International Airport "Eleftherios Venizelos"',
  7: 'New Way',
};

export const HANDOVER_CARS_STAGE = {
  0: 'Standard',
  2: 'Closed Contract',
  3: 'Temp',
  4: 'Predel',
};

export const AGENT_ROLES = {
  isAdmin: 'Admin',
  isCustomerCareAgent: 'CustomerCareAgent',
  isFleetOpsAssociate: 'FleetOpsAssociate',
  isProjectManager: 'ProjectManager',
  isSalesConsultant: 'SalesConsultant',
  isSupplyChainAssociate: 'SupplyChainAssociate',
  isPerformanceMarketingSpecialist: 'PerformanceMarketingSpecialist',
  isTreasurer: 'Treasurer',
  isGraphicDesigner: 'GraphicDesigner',
  isControllingManager: 'ControllingManager',
  isHeadOfMarketing: 'HeadOfMarketing',
  isAssistantAccountant: 'AssistantAccountant',
  isInsuranceSpecialist: 'InsuranceSpecialist',
  isSocialMediaManager: 'SocialMediaManager',
  isSeniorSalesConsultant: 'SeniorSalesConsultant',
  isCustomerSuccessCoordinator: 'CustomerSuccessCoordinator',
  isAccountant: 'Accountant',
  isARMTeamLead: 'ARMTeamLead',
  isProductDesigner: 'ProductDesigner',
  isCreditManager: 'CreditManager',
  isOperationsDirector: 'OperationsDirector',
  isAccidentsSeniorAssociate: 'AccidentsSeniorAssociate',
  isContentMarketingManager: 'ContentMarketingManager',
  isSeniorAccountant: 'SeniorAccountant',
  isBillingAssociate: 'BillingAssociate',
  isCreditControlAssociate: 'CreditControlAssociate',
  isCFO: 'CFO',
  isRepairsMaintenanceAssociate: 'RepairsMaintenanceAssociate',
  isFinancialController: 'FinancialController',
  isCreditControlLead: 'CreditControlLead',
  isCustomerSuccessTeamLead: 'CustomerSuccessTeamLead',
  isHeadOfProduct: 'HeadOfProduct',
  isFleetOpsTeamLead: 'FleetOpsTeamLead',
  isProductOperationsAssociate: 'ProductOperationsAssociate',
  isReturnsDeliveriesLead: 'ReturnsDeliveriesLead',
  isHRSupervisor: 'HRSupervisor',
  isSalesTeamLead: 'SalesTeamLead',
  isExternalPartner: 'ExternalPartner',
  isProductContentEditor: 'Product Content Editor',
  isHeadOfSourcing: 'HeadOfSourcing',
  isExternalAgent: 'ExternalAgent',
};

export const AGENT_ROLES_OPTIONS = {
  isAdmin: { text: 'Admin', value: 'Admin' },
  isCustomerCareAgent: {
    text: 'Customer Care Agent',
    value: 'CustomerCareAgent',
  },
  isFleetOpsAssociate: {
    text: 'Fleet Ops Associate',
    value: 'FleetOpsAssociate',
  },
  isProjectManager: { text: 'Project Manager', value: 'ProjectManager' },
  isSalesConsultant: { text: 'Sales Consultant', value: 'SalesConsultant' },
  isSupplyChainAssociate: {
    text: 'Supply Chain Associate',
    value: 'SupplyChainAssociate',
  },
  isPerformanceMarketingSpecialist: {
    text: 'Performance Marketing Specialist',
    value: 'PerformanceMarketingSpecialist',
  },
  isTreasurer: { text: 'Treasurer', value: 'Treasurer' },
  isGraphicDesigner: { text: 'Graphic Designer', value: 'GraphicDesigner' },
  isControllingManager: {
    text: 'Controlling Manager',
    value: 'ControllingManager',
  },
  isHeadOfMarketing: { text: 'Head Of Marketing', value: 'HeadOfMarketing' },
  isAssistantAccountant: {
    text: 'Assistant Accountant',
    value: 'AssistantAccountant',
  },
  isInsuranceSpecialist: {
    text: 'Insurance Specialist',
    value: 'InsuranceSpecialist',
  },
  isSocialMediaManager: {
    text: 'Social Media Manager',
    value: 'SocialMediaManager',
  },
  isSeniorSalesConsultant: {
    text: 'Senior Sales Consultant',
    value: 'SeniorSalesConsultant',
  },
  isCustomerSuccessCoordinator: {
    text: 'Customer Success Coordinator',
    value: 'CustomerSuccessCoordinator',
  },
  isAccountant: { text: 'Accountant', value: 'Accountant' },
  isARMTeamLead: { text: 'ARM Team Lead', value: 'ARMTeamLead' },
  isProductDesigner: { text: 'Product Designer', value: 'ProductDesigner' },
  isCreditManager: { text: 'Credit Manager', value: 'CreditManager' },
  isOperationsDirector: {
    text: 'Operations Director',
    value: 'OperationsDirector',
  },
  isAccidentsSeniorAssociate: {
    text: 'Accidents Senior Associate',
    value: 'AccidentsSeniorAssociate',
  },
  isContentMarketingManager: {
    text: 'Content Marketing Manager',
    value: 'ContentMarketingManager',
  },
  isSeniorAccountant: {
    text: 'Senior Accountant',
    value: 'SeniorAccountant',
  },
  isBillingAssociate: { text: 'Billing Associate', value: 'BillingAssociate' },
  isCreditControlAssociate: {
    text: 'Credit Control Associate',
    value: 'CreditControlAssociate',
  },
  isCFO: { text: 'CFO', value: 'CFO' },
  isRepairsMaintenanceAssociate: {
    text: 'Repairs Maintenance Associate',
    value: 'RepairsMaintenanceAssociate',
  },
  isFinancialController: {
    text: 'Financial Controller',
    value: 'FinancialController',
  },
  isCreditControlLead: {
    text: 'Credit Control Lead',
    value: 'CreditControlLead',
  },
  isCustomerSuccessTeamLead: {
    text: 'Customer Success Team Lead',
    value: 'CustomerSuccessTeamLead',
  },
  isHeadOfProduct: { text: 'Head Of Product', value: 'HeadOfProduct' },
  isFleetOpsTeamLead: {
    text: 'Fleet Ops Team Lead',
    value: 'FleetOpsTeamLead',
  },
  isProductOperationsAssociate: {
    text: 'Product Operations Associate',
    value: 'ProductOperationsAssociate',
  },
  isReturnsDeliveriesLead: {
    text: 'Returns Deliveries Lead',
    value: 'ReturnsDeliveriesLead',
  },
  isHRSupervisor: { text: 'HR Supervisor', value: 'HRSupervisor' },
  isSalesTeamLead: { text: 'Sales Team Lead', value: 'SalesTeamLead' },
  isExternalPartner: { text: 'External Partner', value: 'ExternalPartner' },
  isProductContentEditor: {
    text: 'Product Content Editor',
    value: 'ProductContentEditor',
  },
  isHeadOfSourcing: { text: 'Head Of Sourcing', value: 'HeadOfSourcing' },
};

export const CONTACT_ROLES = [
  {
    text: 'Technician',
    value: 'Technician',
  },
  {
    text: 'Call Center Agent',
    value: 'Call Center Agent',
  },
  {
    text: 'Accounting',
    value: 'Accounting',
  },
  {
    text: 'Legal',
    value: 'Legal',
  },
  {
    text: 'Claims/Insurance Agent',
    value: 'Claims/Insurance Agent',
  },
];

export const ENTITIES = {
  agents: 'agents',
  bookings: 'bookings',
  catalog: 'catalog',
  configurations: 'configurations',
  ebikes: 'ebikes',
  errors: 'errors',
  graphs: 'graphs',
  insurances: 'insurances',
  maps: 'maps',
  roadsideAssistances: 'roadsideAssistances',
  search: 'search',
  servicePoints: 'servicePoints',
  serviceVehicles: 'serviceVehicles',
  subscribers: 'subscribers',
  subscriptions: 'subscriptions',
  users: 'users',
  utilities: 'utilities',
  vehicles: 'vehicles',
};

export const SCOPES = {
  canCreate: 'write',
  canEdit: 'update',
  canDelete: 'delete',
  canView: 'read',
};

export const PROCUREMENT_STAGES = {
  CREATED: 0,
  ORDERED: 1,
  'IN CUSTOMS': 2,
  'PAYMENT PROCESS': 3,
  'DEALER PREPARATION': 4,
  FLEET: 5,
  DEFLEETED: 6,
  CANCELLED: 7,
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const REGEX_PATTERNS = {
  Boolean: /test. *true,false/,
  Phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
  Email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  String: /^$/,
  Numbers: /^[0-9]+$/,
  Letters: /^[^\d]*$/,
};

export const FILTER_RULE_ENUMS = {
  AndRuleType: 0,
  OrRuleType: 1,
  EmptyRuleType: 2,
  NotRuleType: 3,
  OrderedRuleType: 4,
  ComparableRuleType: 5,
  DynamicFilterFieldRuleType: 6,
  CompareDateRuleType: 7,
  IsAnyOfRuleType: 8,
  CompareStringRuleType: 9,
  ComparePriceRuleType: 10,
  DateBetween: 11,
  isEmpty: 18,
  noEmpty: 19,
};

export const FILTER_OPERATORS = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  GREATER_THAN: '>',
  LOWER_THAN: '<',
  GREATER_OR_EQUAL: '>=',
  LOWER_OR_EQUAL: '<=',
  CONTAINS: 'contains',
  BEGINS_WITH: 'beginsWith',
  ENDS_WITH: 'endsWith',
  BETWEEN: 'between',
};

export const FILTER_OPERATOR_ENUMS = {
  '=': 0,
  '!=': 1,
  '>': 2,
  '<': 3,
  '>=': 4,
  '<=': 5,
  contains: 6,
  beginsWith: 7,
  endsWith: 8,
  between: 9,
};

export const FILTER_RULE_OPERATORS = {
  AndRuleType: 0,
  OrRuleType: 1,
  EmptyRuleType: 2,
  NotRuleType: 3,
  OrderedRuleType: [
    { name: FILTER_OPERATORS.EQUAL, label: '=' },
    { name: FILTER_OPERATORS.NOT_EQUAL, label: '!=' },
    { name: FILTER_OPERATORS.GREATER_THAN, label: '>' },
    { name: FILTER_OPERATORS.GREATER_OR_EQUAL, label: '>=' },
    { name: FILTER_OPERATORS.LOWER_THAN, label: '<' },
    { name: FILTER_OPERATORS.LOWER_OR_EQUAL, label: '<=' },
  ],
  ComparableRuleType: [
    { name: FILTER_OPERATORS.EQUAL, label: '=' },
    { name: FILTER_OPERATORS.NOT_EQUAL, label: '!=' },
  ],
  DynamicFilterFieldRuleType: 6,
  CompareDateRuleType: [
    { name: FILTER_OPERATORS.EQUAL, label: '=' },
    { name: FILTER_OPERATORS.NOT_EQUAL, label: '!=' },
    { name: FILTER_OPERATORS.GREATER_THAN, label: '>' },
    { name: FILTER_OPERATORS.GREATER_OR_EQUAL, label: '>=' },
    { name: FILTER_OPERATORS.LOWER_THAN, label: '<' },
    { name: FILTER_OPERATORS.LOWER_OR_EQUAL, label: '<=' },
  ],
  IsAnyOfRuleType: 8,
  CompareStringRuleType: [
    { name: FILTER_OPERATORS.EQUAL, label: '=' },
    { name: FILTER_OPERATORS.NOT_EQUAL, label: '!=' },
    { name: FILTER_OPERATORS.GREATER_THAN, label: '>' },
    { name: FILTER_OPERATORS.GREATER_OR_EQUAL, label: '>=' },
    { name: FILTER_OPERATORS.LOWER_THAN, label: '<' },
    { name: FILTER_OPERATORS.LOWER_OR_EQUAL, label: '<=' },
    { name: FILTER_OPERATORS.CONTAINS, label: 'contains' },
    { name: FILTER_OPERATORS.BEGINS_WITH, label: 'begins with' },
    { name: FILTER_OPERATORS.ENDS_WITH, label: 'ends with' },
  ],
  ComparePriceRuleType: [
    { name: FILTER_OPERATORS.EQUAL, label: '=' },
    { name: FILTER_OPERATORS.NOT_EQUAL, label: '!=' },
    { name: FILTER_OPERATORS.GREATER_THAN, label: '>' },
    { name: FILTER_OPERATORS.GREATER_OR_EQUAL, label: '>=' },
    { name: FILTER_OPERATORS.LOWER_THAN, label: '<' },
    { name: FILTER_OPERATORS.LOWER_OR_EQUAL, label: '<=' },
  ],
  DateBetween: 11,
};

export const FILTER_FIELDS_TYPES = {
  Select: 'dropdown',
  String: 'string',
  Number: 'number',
  Date: 'date',
  Boolean: 'boolean',
  Phone: 'phone',
  Email: 'email',
};

export const FILTER_FIELDS = {
  Price: {
    type: FILTER_FIELDS_TYPES.String,
    ruleType: FILTER_RULE_ENUMS.ComparePriceRuleType,
    pattern: REGEX_PATTERNS.String,
    operators: FILTER_RULE_OPERATORS.ComparePriceRuleType,
    inputType: 'number',
  },
  String: {
    type: FILTER_FIELDS_TYPES.String,
    ruleType: FILTER_RULE_ENUMS.ComparableRuleType,
    pattern: REGEX_PATTERNS.String,
    operators: FILTER_RULE_OPERATORS.CompareStringRuleType,
  },
  Number: {
    type: FILTER_FIELDS_TYPES.Number,
    ruleType: FILTER_RULE_ENUMS.OrderedRuleType,
    pattern: REGEX_PATTERNS.String,
    operators: FILTER_RULE_OPERATORS.OrderedRuleType,
    inputType: 'number',
  },
  Select: {
    type: FILTER_FIELDS_TYPES.Select,
    ruleType: FILTER_RULE_ENUMS.ComparableRuleType,
    pattern: REGEX_PATTERNS.String,
    operators: FILTER_RULE_OPERATORS.ComparableRuleType,
    valueEditorType: 'select',
    values: [],
    inputType: 'dropdown',
  },
  Date: {
    ruleType: FILTER_RULE_ENUMS.CompareDateRuleType,
    type: FILTER_FIELDS_TYPES.Date,
    pattern: REGEX_PATTERNS.String,
    operators: [
      ...FILTER_RULE_OPERATORS.CompareDateRuleType,
      { name: 'between', label: 'between' },
    ],
    inputType: 'date',
  },
  DateRange: {
    ruleType: FILTER_RULE_ENUMS.CompareDateRuleType,
    type: FILTER_FIELDS_TYPES.Date,
    pattern: REGEX_PATTERNS.String,
    operators: [{ name: 'between', label: 'between' }],
    inputType: 'date',
  },
  Boolean: {
    type: FILTER_FIELDS_TYPES.Boolean,
    ruleType: FILTER_RULE_ENUMS.ComparableRuleType,
    pattern: REGEX_PATTERNS.Boolean,
    operators: FILTER_RULE_OPERATORS.ComparableRuleType,
    valueEditorType: 'checkbox',
    defaultValue: false,
    inputType: 'checkbox',
  },
  Phone: {
    type: FILTER_FIELDS_TYPES.Phone,
    pattern: REGEX_PATTERNS.Phone,
    ruleType: FILTER_RULE_ENUMS.CompareStringRuleType,
    inputType: 'number',
    operators: FILTER_RULE_OPERATORS.CompareStringRuleType,
  },
  Email: {
    type: FILTER_FIELDS_TYPES.Email,
    pattern: REGEX_PATTERNS.Email,
    ruleType: FILTER_RULE_ENUMS.ComparableRuleType,
    operators: FILTER_RULE_OPERATORS.CompareStringRuleType,
  },
};

export const BOARDS = {
  SERVICES: 0,
  RETURNS: 1,
  BUY_OUT: 4,
  ARM_ACCIDENTS: 5,
  ARM_REPAIRS: 6,
  ARM_MAINTENANCE: 7,
  GREEN_CARD: 8,
  TRAFFIC_OFFENSES: 9,
  SELL_REQUESTS: 10,
  BILLING_INSTACAR: 11,
  BILLING_INSTARIDE: 12,
  BILLING_REFUNDS: 13,
  BILLING_DAMAGES: 14,
  BILLING_OTHER: 15,
  DEFLEET: 16,
};

export const BOARDS_ID = {
  RETURNS: 'RET',
  BUY_OUT: 'BUY',
  ARM_ACCIDENTS: 'AC',
  ARM_REPAIRS: 'REP',
  ARM_MAINTENANCE: 'MAIN',
  GREEN_CARD: 'GC',
  TRAFFIC_OFFENSES: 'TROF',
  SELL_REQUESTS: 'VSL',
  BILLING_INSTACAR: 'BIC',
  BILLING_INSTARIDE: 'BIR',
  BILLING_REFUNDS: 'BR',
  BILLING_DAMAGES: 'BD',
  BILLING_OTHER: 'BO',
  SEL_VEHICLES: 'VSL',
  DEFLEET: 'DEF',
};

export const CHANGELOG_ITEMS = {
  TASK_CREATE: 0,
  TASK_FIELD_UPDATE: 1,
  BOARD_FIELD_UPDATE: 2,
};

export const CATALOG_TABS = {
  '': 0,
  properties: 1,
  media: 2,
  pricing: 3,
  documents: 4,
  vehicles: 5,
};

export const CATALOG_TABS_ENUMS = {
  0: '',
  1: 'properties',
  2: 'media',
  3: 'pricing',
  4: 'documents',
  5: 'vehicles',
};

export const ELECTRIC_VEHICLES_TYPES = ['mhev', 'hev', 'phev', 'bev'];
export const SERVICE_POINT_TABS = {
  '': 0,
  partnershipDetails: 1,
  reviews: 2,
};

export const SERVICE_POINT_TABS_ENUMS = {
  0: '',
  1: 'partnershipDetails',
  2: 'reviews',
};

export const ARNM_REASONS_ACCIDENTS = [
  { text: 'Ατύχημα/Συμβάν', value: 'AC14' },
  { text: 'Κλοπή', value: 'ST15' },
  { text: 'Θραύση Κρυστάλλων', value: 'GB16' },
];

export const ARNM_REASONS_MAP = {
  OT01: 'Other',
  RE02: 'Repair',
  SE03: 'Service',
  MA04: 'Malfunction',
  TI05: 'Change tires',
  CH06: 'Vehicle Check',
  KT07: 'KTEO',
  KK08: 'Κάρτα Καυσαερίων',
  PA09: 'Parktronic',
  RR10: 'Roof Rack',
  TW11: 'Tinted Windows',
  AN12: 'Ανάκληση',
  AL13: 'Αλυσίδες',
  AC14: 'Ατύχημα/Συμβάν',
  ST15: 'Κλοπή',
  GB16: 'Θραύση Κρυστάλλων',
};

export const REDIRECT_URL_STORAGE_KEY = 'redirectUrl';

export const SUBSCRIPTIONS_TYPES = {
  0: 'Standard',
  1: 'DriveHome',
  2: 'Closed Contract',
  3: 'Temp',
  4: 'Predel',
};

export const SUBSCRIPTIONS_STATUSES = {
  0: 'Created',
  1: 'For Delivery',
  2: 'Active',
  3: 'For Return',
  4: 'Ended',
  5: 'Inactive',
  6: 'Cancelled',
  8: 'Renewal',
};

export const SUBSCRIPTIONS_STATUSES_ENUM = {
  CREATED: 0,
  FOR_DELIVERY: 1,
  ACTIVE: 2,
  FOR_RETURN: 3,
  ENDED: 4,
  INACTIVE: 5,
  CANCELLED: 6,
  EXPIRING: 7,
  RENEWAL: 8,
};

export const APPROVAL_STATUSES = {
  approved: 'Approved',
  rejected: 'Rejected',
  pending: 'Pending',
};

export const PRICING_TYPES = {
  open: 0,
  fixed: 1,
};
export const PRICING_TYPES_MAP = {
  0: 'Open',
  1: 'Fixed',
};
export const MONTHLY_KILOMETERS = [
  {
    text: '0.08',
    value: '0.08',
  },
  {
    text: '0.10',
    value: '0.10',
  },
  {
    text: '0.14',
    value: '0.14',
  },
  {
    text: '0.16',
    value: '0.16',
  },
  {
    text: '0.18',
    value: '0.18',
  },
];

export const PRICING_CONTRACT_PERIOD_OPTIONS = [
  { text: `12 μήνες`, value: 12 },
  { text: `24 μήνες`, value: 24 },
  { text: `36 μήνες`, value: 36 },
];

export const CONTRACT_TYPES = {
  open: 0,
  fixed: 1,
};

export const SERVICE_POINT_STATUSES = {
  deactive: 0,
  active: 1,
};

export const FLEET_VEHICLE_DOC_ENUMS = {
  CarLicense: 0,
  KTEO: 23,
  VehicleInsurance: 25,
  Other: 27,
  ExhaustCard: 29,
  VehicleLeaseAgreement: 40,
  RetailPriceBefofeTaxCertificate: 42,
  VehicleCertificateOfConformity: 43,
  CodeRadio: 44,
  CodeVehicle: 45,
};

export const FLEET_VEHICLE_DOC_TYPES = [
  { value: 27, text: 'Άλλο' },
  { value: 0, text: 'Άδεια Κυκλοφορίας Οχήματος' },
  { value: 23, text: 'KTEO' },
  { value: 25, text: 'Ασφάλεια Οχήματος' },
  { value: 29, text: 'Κάρτα Καυσαερίων' },
  { value: 42, text: 'Βεβαίωση Λιανικής Τιμής Προ Φόρων' },
  { value: 45, text: 'Κωδικός Οχήματος' },
  { value: 44, text: 'Κωδικός Ραδιοφώνου' },
  { value: 40, text: 'Μισθωτήριο Συμβόλαιο Οχήματος' },
  { value: 43, text: 'Πιστοποιητικό Ταξινόμησης Οχήματος' },
  { value: 20, text: 'Σύμβαση' },
  { value: 14, text: 'Τιμολόγιο' },
];

export const CAMPAIGN_RULES_TYPES = {
  origin: 11,
  vehicleEngine: 13,
  skus: 15,
  vehicles: 16,
  prices: 17,
};

export const RESERVATIONS_TYPES = {
  free: { value: -1, text: 'Free' },
  reserved: { value: 0, text: 'Reserved' },
  onhold: { value: 1, text: 'On Hold' },
  forsale: { value: 2, text: 'For Sale' },
  defleeted: { value: 3, text: 'Defleeted' },
  predeltemp: { value: 4, text: 'Predel/Temp' },
  booked: { value: 5, text: 'Booked' },
  forreplace: { value: 6, text: 'For Replace' },
  legal: { value: 7, text: 'Legal' },
  grandtheft: { value: 8, text: 'Grand Theft' },
  forreturn_r2r: { value: 9, text: 'For return / R2R' },
};

export const AVAILABILITY_STATUSES = {
  onlease: { value: 0, text: 'On Lease' },
  onleasearm: { value: 1, text: 'On Lease ARM' },
  forreturn: { value: 2, text: 'For Return' },
  arm: { value: 3, text: 'ARM' },
  vehicleinspectionpending: { value: 4, text: 'Vehicle Inspection Pending' },
  available: { value: 5, text: 'Available' },
  fordelivery: { value: 6, text: 'For Delivery' },
  forsale: { value: 7, text: 'For Sale' },
};
export const RESERVATIONS_SUBSCRIPTION_TYPES = {
  3: 'Temp',
  4: 'Predel',
};

export const REASONS_OF_REJECTION = [
  { text: 'Price too low', value: 'Price too low' },
  { text: 'Price too high', value: 'Price too high' },
  { text: 'Data inaccuracy', value: 'Data inaccuracy' },
  { text: 'Vehicle not for sale', value: 'Vehicle not for sale' },
  { text: 'Other (please specify)', value: 'Other' },
];

export const SUBSCRIBER_ROLES = {
  owner: { value: 1, text: 'OWNER' },
  driver: { value: 2, text: 'DRIVER' },
  admin: { value: 3, text: 'ADMIN' },
};

export const CANCELATION_REASON = [
  {
    text: 'Sold',
    value: 'sold',
  },
  {
    text: 'Not interested',
    value: 'not-interested',
  },
  {
    text: '0% Deposit requested',
    value: 'zero-percent-deposit-requested',
  },
  {
    text: 'Financial issues',
    value: 'financial-issues',
  },
  {
    text: 'Other',
    value: 'other',
  },
];

export const LEAD_QUALITY = [
  {
    text: 'Cold',
    value: 'cold',
  },
  {
    text: 'Hot',
    value: 'hot',
  },
  {
    text: 'Pending',
    value: 'pending',
  },
];
export const DRIVERS_TABS = {
  deliveries: 0,
  returns: 1,
  inspections: 2,
};

export const DELIVERY_RETURN_POINTS = [
  {
    text: DEFAULT_LOCATION,
    value: DEFAULT_LOCATION,
  },
  {
    text: LOCATION_GERAKAS,
    value: LOCATION_GERAKAS,
  },
  {
    text: LOCATION_ASPROPIRGOS,
    value: LOCATION_ASPROPIRGOS,
  },
  {
    text: LOCATION_ARGYROUPOLI,
    value: LOCATION_ARGYROUPOLI,
  },
  {
    text: 'instadelivery',
    value: 'instadelivery',
  },
  {
    text: 'Airport ATH',
    value: LOCATION_ATHENS_INTERNATIONAL_AIRPORT,
  },
];

export const LANGUAGES = [
  {
    text: 'Ελληνικά',
    value: 'el',
  },
  {
    text: 'English',
    value: 'en',
  },
];

export const EXPORT_DATA_JOB_STATUS_ENUMS = {
  pending: 0,
  inProgress: 1,
  completed: 2,
  failed: 3,
};

export const EXPORT_DATA_JOB_TYPE = {
  0: 'Job Export Vehicle UTR',
  1: 'Job Export Availability',
  2: 'Job Export AR&M',
};
