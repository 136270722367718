/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectArnmAccidentsState,
  getArnmAccidents,
  getArnmAccidentsAssignees,
  selectArnmAccidentsColumnsState,
} from '@store';
import { showError } from '@utils';
import { BOARDS, BOARDS_ID } from '@constants';

export const ARNM_ACCIDENTS_EXPORT_LIMIT = 100000;

export const useArnmAccidentsOperations = (fetchOnMount) => {
  const {
    total,
    loading,
    arnmAccidents,
    pageSize,
    hasErrors,
    errorMessage,
    assignees,
  } = useSelector(selectArnmAccidentsState);
  const {
    isReady,
    push,
    query: { page = 1, orderBy = 'createdAt', order = 'desc', token },
  } = useRouter();
  const dispatch = useDispatch();
  const columns = useSelector(selectArnmAccidentsColumnsState);
  const [payloadObj, setPayloadObj] = useState({});

  const _getArnmAccidents = useCallback(async () => {
    let payload = localStorage.arnmAccidentsQuery;
    let { arnmAccidentsFilterRules } = localStorage;
    const defaultRule = {
      type: 5,
      field: 'boardId',
      operator: 0,
      value: BOARDS_ID.ARM_ACCIDENTS,
    };

    if (arnmAccidentsFilterRules) {
      arnmAccidentsFilterRules = JSON.parse(arnmAccidentsFilterRules);
    } else {
      arnmAccidentsFilterRules = [];
    }
    arnmAccidentsFilterRules = [defaultRule, ...arnmAccidentsFilterRules];
    if (payload) {
      payload = JSON.parse(payload);
      if (payload.value) {
        payload.value = payload.value.map((item) => {
          if (item.field === 'customFields.vehiclePlate') {
            return { ...item, value: item.value.toUpperCase() };
          }
          return item;
        });
        payload.value = [...payload.value, ...arnmAccidentsFilterRules];
      } else if (arnmAccidentsFilterRules?.length) {
        const newRules = arnmAccidentsFilterRules?.filter(
          (item) => !!item?.value
        );
        payload = {
          type: 0,
          field: null,
          operator: null,
          value: newRules,
        };
      }
    } else if (!payload && arnmAccidentsFilterRules?.length) {
      const newRules = arnmAccidentsFilterRules?.filter(
        (item) => !!item?.value
      );
      payload = {
        type: 0,
        field: null,
        operator: null,
        value: newRules,
      };
    } else {
      payload = defaultRule;
    }
    setPayloadObj(payload);
    await dispatch(
      getArnmAccidents({
        orderBy,
        order,
        page,
        pageSize,
        boardId: BOARDS_ID.ARM_ACCIDENTS,
        boardType: BOARDS.ARM_ACCIDENTS,
        ...payload,
      })
    );
  }, [page, orderBy, order, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/arnmAccidents?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/arnmAccidents?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/arnmAccidents?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/arnmAccidents?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    setTimeout(() => {
      if (fetchOnMount && isReady) {
        _getArnmAccidents();
      }
    }, 100);
  }, [page, token, _getArnmAccidents, fetchOnMount, isReady]);

  useEffect(() => {
    dispatch(getArnmAccidentsAssignees({ page: 1, pageSize: 1000 }));
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  const normalizedAssignees = [
    { value: '', text: '-' },
    ...assignees.data.map((assignee) => ({
      value: assignee.value,
      text: assignee.label,
    })),
  ];

  const getAccidensExportPayload = () => {
    return {
      boardId: BOARDS_ID.ARM_ACCIDENTS,
      payload: {
        type: 'csv',
        rules: payloadObj,
      },
      params: {
        page: 1,
        pageSize: ARNM_ACCIDENTS_EXPORT_LIMIT,
      },
    };
  };

  return {
    page,
    total,
    pageSize,
    loading,
    arnmAccidents,
    columns,
    hasErrors,
    errorMessage,
    assignees: normalizedAssignees,
    getArnmAccidents: _getArnmAccidents,
    nextPage: _nextPage,
    sortBy: _sortBy,
    getAccidensExportPayload,
  };
};
