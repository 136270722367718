import { BOARDS_ID } from '@constants';
import { formatDate } from '@utils';
import { ARNM_REASONS_ACCIDENTS_MAP } from 'components/arnmAccidents/constants';

export const handleArnmAccidentsData = (data, assignees) => {
  if (!data?.length) {
    return {};
  }
  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0] || ''}${
        cur.lastName?.[0] || ''
      }`.toUpperCase(),
    };
    return acc;
  }, {});
  const tasks = [];
  data?.forEach((item) => {
    const assignee = assigneesMap[item.assignee] || {};
    const newTasks = {
      ...item,
      href: `/arnmAccidents/${item?.id}`,
      status: item?.status,
      description: item?.name,
      assignee,
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      // TODO: handle custom fields
      id: item?.id,
      colorName: item?.customFields?.doWeNeedATempCar && 'red',
      servicePoint: item?.customFields?.servicePoint || '-',
      reason: ARNM_REASONS_ACCIDENTS_MAP[item?.customFields?.reason] || '-',
      outDateFromServicePoint: item?.customFields?.outDateFromServicePoint
        ? formatDate(item?.customFields?.outDateFromServicePoint, 'DD/MM/YYYY')
        : '-',
    };
    tasks.push(newTasks);
  });

  return {
    boardId: BOARDS_ID.ARM_ACCIDENTS,
    tasks,
  };
};

export const handleAssigneeData = (data = []) =>
  data
    ?.sort((a, b) => a.firstName.localeCompare(b.firstName))
    ?.map((item) => ({
      isActive: item?.isActive,
      value: item.email,
      label:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
    }));
