import theme from 'config/theme';

export default {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
    padding: '8px 0px',
    height: '80px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '8px',
    backgroundColor: theme.palette.white.paper,
    position: 'relative',
  },
  innerWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    maxWidth: '840px',
  },
  dragIcon: {
    cursor: 'move',
  },
  imageWrapper: { borderRadius: '8px', cursor: 'zoom-in', height: '62px' },
  imageName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.darkGray.main,
    fontStyle: 'italic',
    maxWidth: '270px',
  },
  upaloadDate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    lineHeight: '20px',
    minWidth: '140px',
    color: theme.palette.darkGray.main,
  },
  editDate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.darkGray.main,
  },
  iconsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 0,
    marginTop: '-10px',
  },
  deleteIcon: {
    backgroundColor: theme.palette.white.paper,
    borderRadius: '4px',
    height: '24px',
    width: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    background: '#fff',
    borderRadius: '4px',
    minWidth: '400px',
  },
  previewModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '466px',
    background: '#fff',
    borderRadius: '4px',
  },
  label: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '23px',
    color: theme.palette.darkGray.main,
    marginBottom: 0,
    marginTop: '0',
    minWidth: '50%',
  },
  fullWidth: {
    width: '100%',
  },
  fieldWrapper: {
    display: 'flex',
    marginBottom: '10px',
    borderBottom: `1px solid ${theme.palette.backgroundGray.default}`,
  },
  field: {
    color: theme.palette.darkGray.main,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    outline: 'none',
    borderBottom: `2px solid transparent`,
    ':hover': {
      borderBottom: `2px solid ${theme.palette.darkGray.main}`,
    },
    ':focus': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  textarea: {
    outline: 'none',
    width: '100%',
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '6px',
    ':hover': {
      border: `2px solid ${theme.palette.darkGray.main}`,
    },
    ':focus': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  formWrapper: {
    marginTop: '16px',
  },
  editImageWrapper: {
    width: '108px',
    height: '80px',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  primaryPhoto: {
    width: '50%',
  },
  checkbox: {
    margin: '0',
    width: '18px',
    height: '18px',
    minWidth: '18px',
    minHeight: '18px',
    borderRadius: '2px',
    color: '#000',
  },
  badge: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    width: '10px',
    height: '10px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
  },
};
