/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { fleetVehiclesServices } from '@services';
import { showError } from '@utils';
import { QUERY_KEYS } from 'constants/queryKeys';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import {
  normalizeColors,
  normalizeDealers,
  normalizeVehicleSourcingTypes,
} from './utils';

export const useAddFleetVehicleOperations = () => {
  const {
    data: colorsData,
    isLoading: isColorsLoading,
    isError: isColorsError,
    error: colorsError,
  } = useQuery([QUERY_KEYS.GET_FLEET_COLORS], () =>
    fleetVehiclesServices.getVehicleColors()
  );

  const {
    data: dealersData,
    isLoading: isDealersLoading,
    isError: isDealersError,
    error: dealersError,
  } = useQuery(
    [QUERY_KEYS.GET_FLEET_DEALERS],
    () => fleetVehiclesServices.getVehicleDealers(),
    {
      enabled: true,
      refetchOnMount: false,
    }
  );

  const {
    data: vehicleSourcingTypesData,
    isLoading: isVehicleSourcingTypesLoading,
    isError: isVehicleSourcingTypesError,
    error: vehicleSourcingTypesError,
  } = useQuery(
    [QUERY_KEYS.GET_FLEET_SOURCING_TYPES],
    () => fleetVehiclesServices.getVehicleSourcingTypes(),
    {
      enabled: true,
      refetchOnMount: false,
    }
  );

  const normalizedColors = normalizeColors(colorsData?.data);

  const normalizedDealers = normalizeDealers(dealersData?.data);

  const normalizedSourcingTypes = normalizeVehicleSourcingTypes(
    vehicleSourcingTypesData?.data
  );

  const loading =
    isVehicleSourcingTypesLoading || isDealersLoading || isColorsLoading;

  const hasErrors =
    isVehicleSourcingTypesError || isDealersError || isColorsError;

  const errorMessage =
    vehicleSourcingTypesError?.message ||
    dealersError?.message ||
    colorsError?.message ||
    hasErrors
      ? GENERIC_ERROR_MESSAGE
      : null;

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    hasErrors,
    errorMessage,
    colors: normalizedColors,
    dealers: normalizedDealers,
    sourcingTypes: normalizedSourcingTypes,
  };
};
