export const AVAILABLE_VIDEO_TAGS = {
  available_in_car_list: 'Car List',
  available_in_car_page: 'Car Page',
};

export const normalizeVideos = (data = []) => {
  return data.map((item) => ({
    ...item,
    skus: item.skus?.join(','),
    ...item.tags?.reduce((acc, cur) => {
      if (AVAILABLE_VIDEO_TAGS[cur]) {
        acc[cur] = true;
      }
      return acc;
    }, {}),
  }));
};
