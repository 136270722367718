import { request } from '@utils';
import { logout } from 'config/firebase';

const login = async (code) => {
  const body = {
    body: { code },
  };
  const results = await request.post(`/v1/me`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const logOut = async () => {
  const results = await request.remove(`/v1/me`);
  logout();
  if (results?.error) {
    return Promise.reject(results?.message);
  }
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('persist:root');
  if (window.location.pathname !== '/login') {
    window.location.replace(`${window.location.origin}/login`);
  }

  return results;
};

const getUser = async () => {
  const results = await request.get(`/v1/me`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export { login, logOut, getUser };
