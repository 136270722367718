/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { availabilityServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';

import {
  getAvailabilityQueryParams,
  getAvailabilityRequestPayload,
} from 'helpers';
import { handleAvailability, handleEnums } from './availabilityTable.handlers';

export const AVAILABILITY_LIMIT = 200;
export const AVAILABILITY_EXPORT_LIMIT = 100000;

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  data: [],
  total: 0,
  pageSize: AVAILABILITY_LIMIT,
  filters: {
    urlParams: undefined,
    modalFiltersRules: undefined,
    modalConditions: undefined,
  },
  enums: {
    loading: false,
    hasErrors: false,
    errorMessage: '',
    data: {
      statues: [],
      reservationsTypes: [],
      brands: [],
      servicePoints: [],
    },
  },
};

// Actual Slice
export const availabilityTableSlice = createSlice({
  name: 'availabilityTable',
  initialState,
  reducers: {
    fetchAvailability: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.data = [];
    },
    fetchAvailabilitySuccess: (state, { payload }) => {
      state.data = handleAvailability(payload?.response?.data);
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.total = payload?.response?.meta?.total || 0;
      state.filters.modalFiltersRules = payload.modalFiltersRules;
      state.filters.urlParams = payload.urlParams;
    },
    fetchAvailabilityFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchAvailabilityEnums: (state) => {
      state.enums.loading = true;
      state.enums.hasErrors = false;
      state.enums.errorMessage = '';
    },
    fetchAvailabilityEnumsFailure: (state, { payload }) => {
      state.enums.loading = false;
      state.enums.hasErrors = false;
      state.enums.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchAvailabilityEnumsSuccess: (state, { payload }) => {
      state.enums.data = handleEnums(payload);
      state.enums.loading = false;
      state.enums.hasErrors = false;
    },
    filtersModalChangeSuccess: (state, { payload }) => {
      state.filters.modalConditions = payload;
      if (!payload?.rules?.length) {
        state.filters.modalFiltersRules = undefined;
      }
    },
    resetModalChangeSuccess: (state) => {
      state.filters.modalConditions = null;
      state.filters.modalFiltersRules = null;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchAvailability,
  fetchAvailabilitySuccess,
  fetchAvailabilityFailure,
  fetchAvailabilityEnums,
  fetchAvailabilityEnumsFailure,
  fetchAvailabilityEnumsSuccess,
  filtersModalChangeSuccess,
  resetModalChangeSuccess,
} = availabilityTableSlice.actions;

export const getAvailability = ({
  urlParams = {},
  modalFiltersRules = null,
}) => {
  return async (dispatch) => {
    dispatch(fetchAvailability());
    try {
      const params = getAvailabilityQueryParams(urlParams);
      const payload = getAvailabilityRequestPayload(
        urlParams,
        modalFiltersRules
      );

      const response = await availabilityServices?.getAvailability({
        params,
        payload,
      });
      response.meta.page = urlParams.page || 1;

      dispatch(
        fetchAvailabilitySuccess({ response, urlParams, modalFiltersRules })
      );
    } catch (error) {
      dispatch(fetchAvailabilityFailure(error));
    }
  };
};

export const getAvailabilityEnums = () => {
  return async (dispatch) => {
    dispatch(fetchAvailabilityEnums());
    try {
      const response = await availabilityServices?.getAvailabilityEnums();
      dispatch(fetchAvailabilityEnumsSuccess(response));
    } catch (error) {
      dispatch(fetchAvailabilityEnumsFailure(error));
    }
  };
};

export const filtersModalChange = (rules) => {
  return (dispatch) => {
    dispatch(filtersModalChangeSuccess(rules));
  };
};
export const resetModalChange = () => {
  return (dispatch) => {
    dispatch(resetModalChangeSuccess());
  };
};

export const selectAvailabilityTableState = (state) => state.availabilityTable;

export default availabilityTableSlice.reducer;
